<script setup lang="ts">
import { formatDate } from '@vueuse/core'
import type { TableColumnType } from 'ant-design-vue'
import SwitchCard from '../../components/switch-card.vue'
import { queryWorkOrderManagerApi, queryWorkOrderWorkedApi } from '@/api/work-order'
import { WorkOrderTypeEnum } from '@/enums/work-order'
import { enumToOptions } from '@/utils/tools'

const columns = [
  { title: '编号', dataIndex: 'id' },
  { title: '指导意见', dataIndex: 'guidance', ellipsis: true },
  { title: '工单状态', dataIndex: 'state', ellipsis: true, customRender: ({ record }) => (WorkOrderStateEnum[record.state as keyof typeof WorkOrderStateEnum]) },
  { title: '工单来源', dataIndex: 'source', ellipsis: true, customRender: ({ record }) => (SourceTypeEnum[record.source as keyof typeof SourceTypeEnum]) },
  { title: '创建时间', dataIndex: 'createdAt', customRender: ({ record }) => (formatDate(new Date(record.createdAt), 'YYYY-MM-DD HH:mm:ss')) },

] as TableColumnType[]

const pageFetchParam = { needPaging: true, columns, apiFun: queryWorkOrderManagerApi }
const { refresh, onPageChange, state } = usePageFetch(pageFetchParam)

async function onSwitch(val: WorkOrderType) {
  if (val === 'SEND') {
    pageFetchParam.apiFun = queryWorkOrderManagerApi
  }
  else {
    pageFetchParam.apiFun = queryWorkOrderWorkedApi
  }

  await refresh()
}
</script>

<template>
  <SwitchCard
    :loading="false" title="未完成工单" :switch-options="enumToOptions(WorkOrderTypeEnum)"
    @on-switch="(val: string) => onSwitch(val as WorkOrderType)"
  >
    <template #default>
      <div style="height: 300px;">
        <base-table v-bind="state" @page-change="onPageChange" />
      </div>
    </template>
  </SwitchCard>
</template>
