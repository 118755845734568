/**
 * 分业查询我发起的订单
 */
export const queryWorkOrderManagerApi = (params: CompletePageParam) => useGet<Paging<WorkOrder>>('/runtime/work-order/manager', params)

/**
 * 分页查询我工作的工单
 */
export const queryWorkOrderWorkedApi = (params: CompletePageParam) => useGet<Paging<WorkOrder>>('/runtime/work-order/worker', params)

/**
 * 新增工单
 */
export const addWorkOrderApi = (data: { guidance: string, workerUserId: number }) => usePost<number>('/runtime/work-order', data)

/**
 * 派发工单
 */
export const assignWorkOrderApi = (data: { workOrderId: number, workerUserId: number }) => usePut<boolean>('/runtime/work-order/assign', data)

/**
 * 完成工单
 */
export const completeWorkOrderApi = (data: { id: number, workerFeedback: string }) => usePut<boolean>('/runtime/work-order/complete', data)

/**
 * 获取工单统计
 */
export const getWorkOrderCountApi = () => useGet<WorkOrderCount>('/runtime/work-order/count')

/**
 * 修改指导意见
 */
export const setWorkOrderGuidanceApi = (data: { id: number, guidance: string }) => usePut<boolean>('/runtime/work-order/guidance', data)

/**
 * 接收工单
 */
export const receiveWorkOrderApi = (workOrderId: number) => usePut<boolean>('/runtime/work-order/receive', { workOrderId })

/**
 * 拒绝工单
 */
export const rejectWorkOrderApi = (workOrderId: number) => usePut<boolean>('/runtime/work-order/reject', { workOrderId })

/**
 * 查询工单详情
 */
export const getWorkOrderDetailApi = (id: number) => useGet<WorkOrder>(`/runtime/work-order/${id}`)

/**
 * 删除工单
 */
export const deleteWorkOrderApi = (id: number) => useDelete<boolean>(`/runtime/work-order/${id}`)
