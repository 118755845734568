<script setup lang="ts">
interface SwitchOption {
  value: WorkOrderType
  label: string
}

const props = defineProps({
  loading: { type: Boolean, default: true },
  title: { type: String, default: '标题' },
  switchOptions: { type: Array<SwitchOption>, default: [] },
//   defaultOptionValue: { type: String, default: '' },
})

const emit = defineEmits<{
  onSwitch: [value: string]
}>()

const salesType = ref('all')
function handleChangeSalesType(e: any) {
  salesType.value = e.target.value
  emit('onSwitch', salesType.value)
}

watchEffect(() => {
  if (props.switchOptions.length > 0) {
    salesType.value = props.switchOptions[0].value
  }
})
</script>

<template>
  <a-card :loading="loading" :title="title">
    <template #extra>
      <a-radio-group :value="salesType" @change="handleChangeSalesType">
        <a-radio-button v-for="option in switchOptions" :key="option.value" :value="option.value">
          {{ option.label }}
        </a-radio-button>
      </a-radio-group>
    </template>
    <slot />
  </a-card>
</template>
