export enum WorkOrderStateEnum {
  ASSIGNED = '已派发',
  COMPLETED = '已完成',
  CREATED = '已创建',
  REJECTED = '已拒绝',
  RECEIVED = '已接收',
}

export enum ActionEnum {
  ASSIGN = '派发',
  RECEIVE = '接收',
  REJECT = '拒绝',
}

export enum WorkOrderTypeEnum {
  SEND = '我发起的',
  WORK = '我执行的',
}

export enum SourceTypeEnum {
  IBMS = 'IBMS',
  MANUAL = '手动创建',
}
